import { render, staticRenderFns } from "./OrderCreationLocation.vue?vue&type=template&id=54234216&"
import script from "./OrderCreationLocation.vue?vue&type=script&lang=ts&"
export * from "./OrderCreationLocation.vue?vue&type=script&lang=ts&"
import style0 from "./OrderCreationLocation.vue?vue&type=style&index=0&id=54234216&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports